.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.oleo-script-swash-caps-regular {
  font-family: "Oleo Script Swash Caps", system-ui;
  font-weight: 400;
  font-style: normal;
}

.oleo-script-swash-caps-bold {
  font-family: "Oleo Script Swash Caps", system-ui;
  font-weight: 700;
  font-style: normal;
}

.btn-custom-red {
  background-color: red;
  border-color: red;
  color: white;
}

.btn-custom-red:hover,
.btn-custom-red:focus,
.btn-custom-red:active {
  background-color: darkred;
  border-color: darkred;
}





