:root {
  --main-blacktext-color: #000; /* Example: black text */
  --main-redtext-color: #ff3c5a; 
  --main-whitetext-color: #fff; 
  /* --main-bg-color: #ff3c5a; 
  --main-border-red-color: #ff3c5a;/* Example: light gray background */
  /* --main-border-color:  #fff;/* Example: black border */
  /* --button-text-color: #fff; /* Example: white text for buttons */
  /* --button-bg-color: #ff3c5a;; Example: black background for buttons   */
} 
.btn-custom-red {
  background-color:var(--main-redtext-color) !important;
  border-color: var(--main-redtext-color) !important;
  color:  var(--main-whitetext-color) !important;
}

.btn-custom-red:hover,
.btn-custom-red:focus,
.btn-custom-red:active {
  background-color: var(--main-redtext-color) !important;
  border-color: var(--main-redtext-color) !important;
  color: var(--main-whitetext-color) !important;
}

/* style.css */
.red-text {
color: var(--main-redtext-color) !important;
}
body .bg-danger {
  background-color: var(--main-redtext-color) !important;/* Example: light red background */
}
/* src/styles.css */
.option-button {
padding: 10px 20px;
margin: 5px;
border: none;
background-color: var(--main-redtext-color) !important;
color: var(--main-redtext-color) !important;
font-size: 16px;
cursor: pointer;
transition: background-color 0.3s ease;
}

/* clears the ‘X’ from Internet Explorer
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration ,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; } 

/* .option-button:hover {
background-color: #005bb5;
}

.option-button:active {
background-color: #003f7f;
} */

.close-button {
position: absolute;
right: 10px;
background: transparent;
border: none;
font-size: 1.5rem;
line-height: 1;
cursor: pointer;
padding: 0;
}

.close-button:hover {
color: var(--main-redtext-color) !important;/* Optional: Change the color on hover */
}

.assistant-font {
  font-family: "Assistant", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 14px;
  text-wrap: true;
}
.large-font {
  font-size: 16px; /* Adjust the size as needed */
}

.horizontal-scroll-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  /* Adjust padding as needed */
}

.horizontal-scroll-container {
  overflow-x: hidden; /* Hide overflow on the x-axis */
}

.react-horizontal-scrolling-menu--scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
}

/* Hide scrollbar for all browsers */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.item-container {
  display: inline-block;
 
  text-align: center;
}

.circle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scroll-menu-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}


.bgcolor{
  border: 2px solid main-blacktext-color; /* Default border */
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}
/* styles.css */
.page-container {
  position: relative;
}
.banner-img {
  width: 100%;

  height: 200px; /* Default to maintain aspect ratio */
}
@media (max-width: 576px) {
  .banner-img {
    height: 80px; /* Adjust this value to make the banner taller on mobile */
    object-fit: cover; /* Ensure the image covers the height */
  }
}

.blur::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.rounded-border {
  border-radius: 10px; /* Adjust the value to make it more or less rounded */
  /* border: 1px solid black; */

}
.rounded-border-product {
  border-radius: 15px; 
   border: 1px solid #007BFF; 

}
.custom-radio input[type="radio"] {
  display: none; /* Hide the default radio button */
}

.custom-radio input[type="radio"] + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  color: #666;
}

.custom-radio input[type="radio"] + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 2px solid var(--main-redtext-color) !important; /* Change border color */
  border-radius: 50%;
  background:var(--main-whitetext-color);
}

.custom-radio input[type="radio"]:checked + label:before {
  background-color: var(--main-redtext-color); /* Change background color when checked */
}

.custom-radio input[type="radio"]:checked + label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--main-whitetext-color);
}

.icon-wrapper {
  display: inline-block;
  background-color: #38cc56; /* Green background */
  border-radius: 50%; /* Fully round */
  width: 24px; /* Adjust width for the circle size */
  height: 24px; /* Adjust height for the circle size */
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-icon {
  color: var(--main-whitetext-color);
}
.icon-wrapper-cart {
  display: inline-block;
  background-color: var(--main-redtext-color); /* Green background */
  border-radius: 20%; /* Fully round */
  width: 24px; /* Adjust width for the circle size */
  height: 24px; /* Adjust height for the circle size */
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-icon-cart {
  color: var(--main-whitetext-color);
  
}
.quanitybg{
  background-color: #ECECEC; 
  width: 24px; 
  height: 24px; 
}
.graybg{
  background-color: #ECECEC; 

}
.redbg{
  background-color: var(--main-redtext-color);

}



/* styles.css */
.custom-link {
  display: flex;
  align-items: center;
  transition: color 0.1s, transform 0.1s;
  cursor: pointer;
  color: var(--main-redtext-color);
}

.custom-link.hovered,
.custom-link:hover,
.custom-link:focus,
.custom-link:active,
.custom-link:visited {
  color: var(--main-whitetext-color);
}

.fa-icon {
  margin-left: 8px; /* Adjust margin as needed */
  transition: color 0.1s, transform 0.1s;
}

.custom-link.hovered .fa-icon,
.custom-link:hover .fa-icon,
.custom-link:active .fa-icon,
.custom-link:visited .fa-icon {
  color: var(--main-whitetext-color);
  transform: scale(1.1); /* Adjust scale as needed */
}
.custom-link:focus .fa-icon{
  color: main-redtext-color;
  transform: scale(1.1); 

}

.badge:hover ~ .fa-icon
{
  color: var(--main-whitetext-color);
  transform: scale(1.1);
}

div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

div {
  scrollbar-width: none; /* for Firefox */
  -ms-overflow-style: none; /* for Internet Explorer and Edge */
}

.single-line {
  padding-bottom: 15px; /* Add margin when text is a single line */
}

.truncate {
  display: -webkit-box;

  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2em; /* Adjust the line height as needed */
  height: 2.4em; /* Set the height to be exactly two lines */
}

.truncate:hover::after {
  content: attr(data-full-text);
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--main-whitetext-color);
  border: 1px solid #ddd;
  padding: 5px;
  white-space: normal;
  z-index: 100;
  max-width: 300px; /* Optional: limit the width of the tooltip */
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
}

/* custom.css */
.nav-pills .nav-link {
  color: var(--main-redtext-color) !important; /* Inactive color */
  background-color: #f8f9fa  !important; /* Background color */
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  color: white  !important; /* Active color */
  background-color: var(--main-redtext-color) !important; /* Active background color */
}
.nav-pills .nav-link.active {
  color: white  !important; /* Active color */
  background-color: var(--main-redtext-color)  !important; /* Active background color */
}
body .btn-danger {
  background-color: var(--main-redtext-color)  !important; 
  border-color: var(--main-redtext-color)  !important;  
}
body .text-danger {
  color: var(--main-redtext-color)  !important; 

}

body .btn-outline-danger {
  background-color: var(--main-whitetext-color)  !important; 
  border-color: var(--main-redtext-color)  !important; 
}


/* Adjust hover, focus, and active states */
body .btn-danger:hover ,
body .btn-danger:focus ,
body .btn-danger:active ,
body .btn-danger.active ,
body .open>.dropdown-toggle.btn-danger  {
  background-color: #cc0000 !important; /* Darker shade for hover/focus/active */
  border-color: #cc0000 !important; 
  color:  var(--main-whitetext-color)  !important;
}
body  .btn-outline-danger:hover,
body  .btn-outline-danger:hover,
body .btn-outline-danger:hover,
body .btn-outline-danger:hover,
body .open>.dropdown-toggle.btn-outline-danger {
  background-color: var(--main-redtext-color)  !important;  /* Darker shade for hover/focus/active */
  border-color: var(--main-redtext-color)  !important; 
  color:  var(--main-whitetext-color)  !important;
}

/* Ensure text color remains white */
body .btn-danger  {
  color: #fff !important;
}
body .btn-outline-danger   {
  color:  var(--main-redtext-color)  !important;
}

/* src/FloatingCartIcon.css */
  
.nav-item-cart {
  position: relative;
}

.icon-wrapper-top-cart {
  position: relative;
  display: inline-block;
}


.hidden {
  display: none;
}

.visible {
  display: block;
}

.form-check-input:checked{
  background-color: var(--main-redtext-color) !important;
  border: var(--main-whitetext-color) !important;
} 

.img-fluid-product {
  width: 100%; /* Make sure the image takes the full width */
  height: 125px; /* Set a fixed height for uniformity */
  object-fit: cover;
   /* This ensures the image covers the card without stretching */
}
.img-fluid-product-default {
  width: 150px; /* Set your desired width */
  height: auto; /* Keep aspect ratio */
  padding: 10px; /* Add padding */
  border: 2px solid white; /* Adjust border */
  border-radius: 10px; /* Adjust border radius if needed */

   /* This ensures the image covers the card without stretching */
}

/* for banner image */
.carousel-indicators {
  margin-bottom: 0 !important; /* Removes margin */
  /* Adjust this value to control how far from the bottom it should be */
}

.carousel-indicators li {
  width: 10px; /* Optional: Adjust the size of the indicators */
  height: 10px;
  border-radius: 50%; /* Optional: Make indicators circular */
  background-color: rgba(255, 255, 255, 0.5); /* Optional: Customize color */
}

.carousel-indicators .active {
  background-color: #fff; /* Color for active indicator */
}
.carousel-inner {
  border-radius: 10px;

}


  
    




